@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

:root {
  --primary: #00c2a8;
  --primary-hover: #00a08e;
}

body {
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.shadow-text {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.bg-primary {
  background-color: var(--primary);
}

.text-primary {
  color: var(--primary);
}

.hover\:bg-primary:hover {
  background-color: var(--primary-hover);
}

.focus\:ring-primary:focus {
  --tw-ring-color: var(--primary);
}

.container {
  text-align: center;
}

.parameet-img {
  width: 800px;
}

.video {
  width: 800px;
  height: 440px;
  overflow: 'auto';
}

@media (max-width: 800px) {
  .parameet-img {
    width: 400px;
  }

  .video {
    width: 400px;
    height: 220px;
  }
}
